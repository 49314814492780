import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box, Button, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import DocumentText from '../../icons/DocumentText';

// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BuildIcon from '@material-ui/icons/Build';
// import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { Settings } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';

import ViewInArIcon from '@material-ui/icons/ViewInAr';

import { PERMISSION_ENTITY, usePermissions } from 'src/hooks/usePermissions';

const sections = [
  // {
  //   title: 'General',
  //   items: [
  //     // {
  //     //   title: 'Overview',
  //     //   path: '/dashboard',
  //     //   icon: <ChartSquareBarIcon fontSize="small" />
  //     // },
  //     // {
  //     //   title: 'Analytics',
  //     //   path: '/dashboard/analytics',
  //     //   icon: <ChartPieIcon fontSize="small" />
  //     // },
  //     // {
  //     //   title: 'Finance',
  //     //   path: '/dashboard/finance',
  //     //   icon: <ShoppingBagIcon fontSize="small" />
  //     // },
  //     {
  //       title: 'Account',
  //       path: '/dashboard/account',
  //       icon: <UserIcon fontSize="small" />
  //     }
  //   ]
  // },
  {
    title: 'Management',
    items: [
      {
        title: 'Assets',
        path: '/dashboard/assets',
        permissions: PERMISSION_ENTITY.ASSET,
        icon: <ViewInArIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            permissions: PERMISSION_ENTITY.ASSET,
            path: '/dashboard/assets'
          },
          {
            title: 'Create',
            permissions: PERMISSION_ENTITY.ASSET,
            path: '/dashboard/assets/new'
          }
        ]
      },
      {
        title: 'Users',
        path: '/dashboard/users',
        permissions: PERMISSION_ENTITY.USER,
        icon: <PersonIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            permissions: PERMISSION_ENTITY.USER,
            path: '/dashboard/users'
          },
          {
            title: 'Create',
            permissions: PERMISSION_ENTITY.USER,
            path: '/dashboard/users/new'
          }
        ]
      },
      {
        title: 'Customers',
        permissions: PERMISSION_ENTITY.CUSTOMER,
        path: '/dashboard/customers',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            permissions: PERMISSION_ENTITY.CUSTOMER,
            path: '/dashboard/customers'
          },
          {
            title: 'Statistics',
            permissions: PERMISSION_ENTITY.CUSTOMER,
            path: '/dashboard/customers/statistics'
          },
          // {
          //   title: 'Details',
          //   path: '/dashboard/customers/1'
          // },
          // {
          //   title: 'Edit',
          //   path: '/dashboard/customers/1/edit'
          // }
        ]
      },
      {
        title: 'Products',
        permissions: PERMISSION_ENTITY.PRODUCT,
        path: '/dashboard/products',
        icon: <ShoppingCartIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/products',
            permissions: PERMISSION_ENTITY.PRODUCT,
          },
          {
            title: 'Paint Colors',
            path: '/dashboard/products/paint-colors',
            permissions: PERMISSION_ENTITY.PRODUCT,
          },
          {
            title: 'Grouts',
            path: '/dashboard/products/grouts',
            permissions: PERMISSION_ENTITY.PRODUCT,
          },
          {
            title: 'Create',
            path: '/dashboard/products/new',
            permissions: PERMISSION_ENTITY.PRODUCT,
          },
        ]
      },
      {
        title: 'Product Upgrades',
        permissions: PERMISSION_ENTITY.PRODUCT_UPGRADE,
        path: '/dashboard/product-upgrades',
        icon: <AttachMoneyIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/product-upgrades',
            permissions: PERMISSION_ENTITY.PRODUCT_UPGRADE,
          },
          {
            title: 'Create',
            path: '/dashboard/product-upgrades/new',
            permissions: PERMISSION_ENTITY.PRODUCT_UPGRADE,
          },
        ]
      },
      {
        title: 'Suppliers',
        permissions: PERMISSION_ENTITY.SUPPLIER,
        path: '/dashboard/suppliers',
        icon: <LocalShippingIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/suppliers',
            permissions: PERMISSION_ENTITY.SUPPLIER,
          },
          {
            title: 'Create',
            path: '/dashboard/suppliers/new',
            permissions: PERMISSION_ENTITY.SUPPLIER,
          },
        ]
      },
      {
        title: 'Contractors',
        permissions: PERMISSION_ENTITY.CONTRACTOR,
        path: '/dashboard/contractors',
        icon: <BuildIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/contractors',
            permissions: PERMISSION_ENTITY.CONTRACTOR,
          },
          {
            title: 'Create',
            path: '/dashboard/contractors/new',
            permissions: PERMISSION_ENTITY.CONTRACTOR,
          },
        ]
      },
      {
        title: 'Orders',
        permissions: PERMISSION_ENTITY.ORDER,
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/dashboard/orders',
        children: [
          {
            title: 'List',
            path: '/dashboard/orders',
            permissions: PERMISSION_ENTITY.ORDER,
          },
          {
            title: 'Work Request Items',
            path: '/dashboard/orders/work-request-items',
            permissions: PERMISSION_ENTITY.WORK_REQUEST,
          },
          {
            title: 'Purchase Order Items',
            path: '/dashboard/orders/purchase-order-items',
            permissions: PERMISSION_ENTITY.PURCHASE_ORDER,
          }
        ]
      },
      {
        title: 'Contracts',
        permissions: PERMISSION_ENTITY.SALER,
        icon: <DocumentText fontSize="small" />,
        path: '/dashboard/contracts',
        children: [
          {
            title: 'Create',
            path: '/dashboard/contracts/new',
            permissions: PERMISSION_ENTITY.SALER,
          },
          {
            title: 'Edit',
            path: '/dashboard/contracts',
            permissions: PERMISSION_ENTITY.SALER,
          }
        ]
      },
      {
        title: 'Settings',
        permissions: PERMISSION_ENTITY.SETTINGS,
        icon: <Settings fontSize="small" />,
        path: '/dashboard/settings',
        children: [
          {
            title: 'Settings',
            path: '/dashboard/settings',
            permissions: PERMISSION_ENTITY.SETTINGS,
          }
        ]
      },
      // {
      //   title: 'Invoices',
      //   path: '/dashboard/invoices',
      //   icon: <ReceiptIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/dashboard/invoices'
      //     },
      //     {
      //       title: 'Details',
      //       path: '/dashboard/invoices/1'
      //     }
      //   ]
      // }
    ]
  }
  // {
  //   title: 'Platforms',
  //   items: [
  //     {
  //       title: 'Projects',
  //       path: '/dashboard/projects',
  //       icon: <BriefcaseIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Browse',
  //           path: '/dashboard/projects/browse'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/projects/1'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/dashboard/projects/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social',
  //       path: '/dashboard/social',
  //       icon: <ShareIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Profile',
  //           path: '/dashboard/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           path: '/dashboard/social/feed'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   title: 'Apps',
  //   items: [
  //     {
  //       title: 'Kanban',
  //       path: '/dashboard/kanban',
  //       icon: <ClipboardListIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Mail',
  //       path: '/dashboard/mail',
  //       icon: <MailIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Chat',
  //       path: '/dashboard/chat',
  //       icon: <ChatAltIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Calendar',
  //       path: '/dashboard/calendar',
  //       icon: <CalendarIcon fontSize="small" />
  //     }
  //   ]
  // }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;

  const { canView } = usePermissions();
  const location = useLocation();
  useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  // TODO: replace it with correct permissions model
  // const filteredSections = sections.map(section => {
  //   if (currentUser.role === USER_ROLE.PROJECT_MANAGER) {
  //     const items = section.items.map(item => {
  //       const children = item.children.filter(c => c.path === ORDER_LIST_PATH);

  //       if (children.length) {
  //         return {
  //           ...item,
  //           children,
  //         }
  //       }
        
  //       return undefined;
  //     }).filter(Boolean)

  //     return items.length 
  //       ? { ...section, items }
  //       : undefined
  //   }

  //   return section;
  // }).filter(Boolean)

  const filteredSections = sections.map(section => {
    
    const items = section.items.map(item => {
      const children = item.children.filter(c => canView(c.permissions));

      if (children.length) {
        return {
          ...item,
          children,
        }
      }
      
      return undefined;
    }).filter(Boolean)

    return items.length 
      ? { ...section, items }
      : undefined

  }).filter(Boolean)


  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box> */}
        <Divider />
        <Box sx={{ p: 2 }}>
          {filteredSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              // sx={{
              //   '& + &': {
              //     mt: 3
              //   }
              // }}
              {...section}
            />
          ))}
        </Box>
        <Divider />

        
        {canView(PERMISSION_ENTITY.SETTINGS) && <Box sx={{ p: 2 }}>

          {/* <Button
            color="primary"
            type="submit"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={()=>{ window.open('/design/','_blank'); }}
          > Models Viewer </Button> */}

          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/documents"
            variant="contained"
          >
            Documentation
          </Button>
        </Box>}
      </Scrollbar>




    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
