import { createSlice } from '@reduxjs/toolkit';

export type ContractState = {
  viewType: 'edit' | 'preview',
}

const initialState: ContractState = {
  viewType: 'preview',
};

const contractSlice = createSlice<ContractState, any>({
  initialState,
  name: 'users',
  reducers: {
    setViewType(state, action) {
      state.viewType = action.payload
    },
  },
});

export const {
  setViewType,
} = contractSlice.actions


export const contractReducer = contractSlice.reducer;
