import { useState, useRef, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import { USER_ROLE } from 'src/types/user';
import { ORDER_LIST_PATH } from 'src/constants';

const AuthGuard = (props, ...rest) => {
  const { children } = props;
  const isAutheticatedRef = useRef(false);
  const { isAuthenticated, currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    const justLoggedIn = !isAutheticatedRef.current && isAuthenticated;
    isAutheticatedRef.current = isAuthenticated
    
    if (justLoggedIn && currentUser.role === USER_ROLE.PROJECT_MANAGER) {
      navigate(ORDER_LIST_PATH)
    }    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])


  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
