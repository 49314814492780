import { combineReducers } from '@reduxjs/toolkit';
import { userListReducer } from 'src/slices/userListSlice';
// import { reducer as calendarReducer } from '../slices/calendar';
// import { reducer as chatReducer } from '../slices/chat';
// import { reducer as kanbanReducer } from '../slices/kanban';
// import { reducer as mailReducer } from '../slices/mail';
import { orderListReducer } from '../slices/orderListSlice';
import { authReducer } from '../slices/authSlice';
import { orderDetailsReducer } from 'src/slices/orderDetailsSlice';
import { contractReducer } from 'src/slices/contractSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  // calendar: calendarReducer,
  // chat: chatReducer,
  // kanban: kanbanReducer,
  // mail: mailReducer,
  orderList: orderListReducer,
  userList: userListReducer,
  orderDetails: orderDetailsReducer,
  contract: contractReducer,
});

export default rootReducer;
