import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as userApi from '../api/userApi';

import { UserListState } from '../types/user';

const initialState: UserListState = {
  list: null,
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async () => {
    const users = await userApi.fetchUsers();
    return users;
  }
);

const userListSlice = createSlice<UserListState, any>({
  initialState,
  name: 'users',
  reducers: {},
  extraReducers: {
    [fetchUsers.pending.toString()]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [fetchUsers.fulfilled.toString()]: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    [fetchUsers.rejected.toString()]: (state, action) => {
      state.error = action.error; // TODO: check error and show in the UI
      state.loading = false;
    },
  },
});

export const userListReducer = userListSlice.reducer;
