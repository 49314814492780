import { APP_SERVER_NAME, APP_SERVER_NAME_HEADER } from './constants/server'

export default class ServerService {

  constructor(props) {
    this.token = props.token;
    this.user_id = props.user_id;
    this.url = props.url;

    this.modelTypes = {}; 
    this.materialTypes = {}; 
    this.modelTypesIds = {}; 
    this.materialTypesIds = {}; 
    this.taxRates = null;
  }

  manageListData = (method, url, data, resolve, reject) => {
 
    if (!url) {
      url = this.url;
    }

    var formData = new FormData();
    formData.append('user_query', JSON.stringify( data ));
    fetch( url, {
     method: method,
     headers: {
      [APP_SERVER_NAME_HEADER]: APP_SERVER_NAME,
     },
     body: formData
    }).then( response => {
        if (response.status === 200 || response.status === 201) return response.json();
    }).then( json => resolve(json) )
    .catch( error => {
      console.error(error)

      if (reject) {
        reject(error);
      }
    });

  }

  modelTypesInit(resolve, reject) {

    this.manageListData(
      'POST', null,
      { mode:'model_type',action:'get',data:{}, token: this.token },
      resp => {

        // console.log( 'modelTypesInit', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resp.data.forEach(item => {
            this.modelTypes[item.name] = item.id;
            this.modelTypesIds[item.id] = () => item.name;
          });
          // let materialTypeData =  this.state.materialTypes.find( type => type.name === materialType);
          // if (resolve) resolve(resp.data);
          resolve(true);
        } else {
          resolve(false);
        }

        // else if (reject) reject(resp);
        // this.token = resp.token;
      }
    );

  }

  materialTypesInit(resolve, reject) {

    this.manageListData(
      'POST', null, { mode:'material_type',action:'get',data:{}, token: this.token },
      resp => {

        // console.log( 'materialTypesInit', resp );
        resolve(true);
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resp.data.forEach( item => {
            this.materialTypes[item.name] = item.id;
            this.materialTypesIds[item.id] = () => item.name;
          });
          // let materialTypeData =  this.state.materialTypes.find( type => type.name === materialType);
          // if (resolve) resolve(resp.data);
        }

        // else if (reject) reject(resp);
        // this.token = resp.token;
      }
    );

  }

  addDefaultKits(resolve, reject, data) {
    this.manageListData(
      'POST', null,
      { 
       mode: 'kit', action: 'add', data, token: this.token
      },
      resp => {
        console.log( 'addDefaultKits', resp );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else reject(false);
      }
    );
  }
  getDefaultKits(resolve, reject, data) {
    this.manageListData(
      'POST', null,
      { 
       mode: 'kit', action: 'get', data: data || {}, token: this.token
      },
      resp => {
        // console.log( 'getDefaultKits', resp );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else reject(false);
      }
    );
  }
  removeDefaultKit(resolve, reject, data) {
    if (!data) {reject(false); return;}
    this.manageListData(
      'POST', null,
      { 
       mode: 'kit', action: 'delete', data, token: this.token
      },
      resp => {
        console.log( 'removeDefaultKit', resp );
        if ( resp.state === 'success') {
          resolve(true);
        } else reject(false);
      }
    );
  }
  getColors(resolve, reject) {
    this.manageListData(
      'POST', null, { mode: 'color', action: 'get', token: this.token },
      resp => {
        if ( resp.state === 'success' && resp.data) {
          resolve(resp.data);
        } else reject(false);
      }
    );
  }

  getSuppliers (resolve, reject) {

    this.manageListData(
      'POST', null,
      { mode: 'supplier', action: 'get', data: {}, token: this.token },
      resp => {
        // console.log( 'getSuppliers get', resp.data );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else reject(false);
      }
    );

  }

  addSupplier (resolve, reject, { name, email }) {
    let data = { name, email };

    this.manageListData(
      'POST', null,
      { mode: 'supplier', action: 'add', data, token: this.token },
      resp => {
        console.log( 'supplier Added', resp );
        if ( resp.state === 'success' && resp.data) {
          if (resolve) resolve(resp.data);
        } else if (reject) reject(false);
      },
      reject,
    );
  }

  addSupplierAsync = (data) => {
    return new Promise( (resolve, reject) => this.addSupplier(resolve, reject, data));
  }

  getTaxRates({ resolve, reject }) {
    if (this.taxRates) {
      resolve(this.taxRates);
      return;
    }

    this.manageListData(
      'POST', null,
      { mode:"tax_rate", action:"get", data: {}, token: this.token },
      resp => {
        if ( resp.state === "success" && resp.data) {
          this.taxRates = resp.data;
          resolve(resp.data);
        } else reject(false);

      }
    );

  }

  getOptions (resolve, reject, id) {

    let data = {};
    if (id) data.id = id;

    this.manageListData(
      'POST', null,
      { mode: 'option', action: 'get', data, token: this.token },
      resp => {
        // console.log( 'Options get', resp.data );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else reject(false);
      }
    );

  }
  addUser(data, resolve, reject) {
      this.manageListData(
        'POST', null,
        {
         mode: 'user', action: 'add',
         data,
         token: this.token
        },
        resp => {
          console.log('resp add user', resp)
          if (resp.state === "success" && resp.data) {
            resolve(resp.data);
          } else reject(false);
        }
      );
  }
  getUser(resolve, reject, user_id) {
      this.manageListData(
        'POST', null,
        { 
         mode: 'user', action: 'get',
         data: {id: user_id},
         token: this.token
        },
        resp => {
          if ( resp.state === 'success' && resp.data) {
            resolve(resp.data[0]);
          } else reject(false);
        }
      );
  }
  getUsers(resolve, reject) {
  // user_id: this.user_id 
    this.manageListData(
      'POST', null,
      { 
       mode: 'user', action: 'get',
       data: {},
       token: this.token
      },
      resp => {
        // console.log( 'users get', resp );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else resolve(false);
      }
    );

  }

  getUserOrders(user_id, resolve, reject) {
    // user_id: this.user_id  {"mode":"order","action":"get","data":{},"token":""}
        this.manageListData(
          'POST', null,
          { 
           mode: 'order', action: 'get',
           data: {user_id},
           token: this.token
          },
          resp => {
            // console.log( 'orderz get', resp );
            // this.token = resp.token;
            if ( resp.state === 'success' && resp.data) {
              // resp.data.forEach( model => {});
              resolve(resp.data);
            } else reject(false);
          }
        );
    
  }
  addOrder(order_data, user_id, token, resolve, reject) {

    this.manageListData(
      'POST', null,
      { 
       mode: "order", action: "add",
       data: {
         user_id, 
         order_data
       },
       token
      },
      resp => {

        console.log( 'order Added', resp, resolve );
        if (resp.state === 'success' && resp.data) {
          if (resolve) resolve(resp.data);
        } else if (reject) reject(false);
  
      }
    );
  
  }
  
  // addOrder(order_data, id, user_id, resolve, reject) {
  //   // {"mode":"order","action":"add","data":{"id":1,"user_id":1,"order_data":{"Bathroom_type":{},"dimensions":{}}},"token":""}
  //   // console.error('addOrder', order_data );
  //   let data = { user_id: this.user_id, order_data };
  //   if (id !== undefined) data.id = id;
  //   if (user_id !== undefined) data.user_id = user_id;

  //   this.manageListData(
  //     'POST', null,
  //     { mode: 'order', action: 'add', data, token: this.token },
  //     resp => {
  //       console.log( 'order Added', resp );
  //       if ( resp.state === 'success' && resp.data) {
  //         // resp.data.forEach( model => {});
  //         if (resolve) resolve(resp.data);
  //       } else if (reject) reject(false);
  //       // this.token = resp.token;
  //     }
  //   );
  // }

  addOrderAsync({ userId, orderData, orderId }) {
    return new Promise( (resolve, reject) => this.addOrder(orderData, orderId, userId, resolve, reject ) );
  }

  getOrder(resolve, reject, user_id) {

    this.manageListData(
      'POST', null,
      { 
       mode: 'order', action: 'get',
       data: { user_id: user_id || this.user_id },
       token: this.token
      },
      resp => {

        if ( resp.state === 'success' && resp.data) {
          resolve(resp.data[0]);
        } else reject(false);
      }
    );

  }

  getOrderAsync({ user_id }) {
    return new Promise( (resolve, reject) => this.getOrder( resolve, reject, user_id ) );
  }
  
  deleteOrder(id, resolve, reject) {
    // {"mode":"order","action":"delete","data":{"id":1},"token":""}

    this.manageListData(
      'POST', null,
      { 
       mode: 'order', action: 'delete',
       data: { id },
       token: this.token
      },
      resp => {
        console.log( 'order deleted', resp, resolve );
        if (resolve) resolve(true);
        // this.token = resp.token;
      }
    );

  }

  deleteOrderAsync({ orderId }) {
    return new Promise( (resolve, reject) => this.deleteOrder( orderId, resolve, reject ) );
  }
  
  updateOrder(resolve, reject, ordersList ) {
    // {"mode":"order","action":"delete","data":{"id":1},"token":""}
    new Promise( (resolve, reject) => this.deleteOrder( ordersList.id, resolve, reject ) ).then( resp => {
      new Promise( (resolve, reject) => this.addOrder( ordersList.order_data, ordersList.id, ordersList.user_id, resolve, reject ) ).then( resp => {
        resolve(resp);
      }).catch( (error) => { console.error(error); reject(error); });
    }).catch( (error) => { console.error(error); reject(error); });
  }


  getOrderList(resolve, reject, user_id) {

    this.manageListData(
      'POST', null,
      { 
       mode: 'order', action: 'get', data: {},
       token: this.token
      },
      resp => {
        // console.log( 'orders loaded', resp );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else reject(false);
      }
    );

  }

  getOrderListAsync({ user_id }) {
    return new Promise( (resolve, reject) => this.getOrderList( resolve, reject, user_id ) );
  }
  getAvailability() {
    return new Promise((resolve, reject) => 
      this.manageListData(
        'POST', null,
        { 
        mode: 'order', action: 'getAvailability', data: {},
        token: this.token
        },
        resp => {
          console.log( 'getAvailability', resp );
          // this.token = resp.token;
          if ( resp.state === 'success' && resp.data) {
            // resp.data.forEach( model => {});
            resolve(resp.data);
          } else reject(false);
        }
      )
    );
  }

  getUserHistory(resolve, reject, user_id) {
    this.manageListData(
      'POST', null, { mode:'history', action: 'get', data: { user_id }, token: this.token },
      resp => {
        // console.log( 'orders loaded', resp );
        // this.token = resp.token;
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data[0]);
        } else reject(false);
      }
    );
  }

  getHistory(resolve, reject) {
    this.manageListData(
      'POST', null, { mode:'history', action: 'get', data: {}, token: this.token },
      resp => {
        if ( resp.state === 'success' && resp.data) {
          resolve(resp.data);
        } else reject(false);
      }
    );
  }

  addModelToDB(resolve, reject, model) {

    // add 3d_model

    // objState.objDataArr.forEach( model => {
    // new Promise((resolve, reject) => getModelTypesFromDB( resolve, reject )).then( respond => { });
    // console.log( 'model', model );
    // });

        // let { name, image, level, params, type, price, inventory} = model;
        // name = name.replace(/”/i, '"');
        // { id: 0, name, type, image, level, price, params, inventory },
        // : {size: params.size, thumUrl: params.thumUrl, url2d: params.url2d, url3d: params.url3d, shopUrl: params.shopUrl }
        // console.log( 'model', model );
        // type - should be an ID of ModelType
        this.manageListData(
          'POST', null,
          { 
           mode:'model', action:'add',
           data: model,
           token: this.token
          },
          resp => {
            if ( resp.state === 'success' && resp.data) {
              // console.log( 'model Added success', resp );
              // data: {id: "164"}
              resolve(resp.data.id);
            } else {
              console.error( 'model Add Error', resp );
              resolve(false);
            }
          }
        );

  }
  getModelsFromDB(resolve, reject, type, availability) {
    // get 3d_model
    let filters = {};
    if (type) filters.type = type;
    if (availability) filters.price = availability;

    let body = { mode: 'model', action: 'get', data: filters, token: this.token };

    // console.log( 'getModelsFromDB filters', filters );

      this.manageListData(
        'POST', null, body, resp => {
          // console.log( 'getModels', resp );
          if ( resp.state === 'success' && resp.data) {
            // resp.data.forEach( model => {});
            if (resolve) resolve(resp.data);
          } else {
            if (reject) reject(resp);
            if (resp.error_desc === 'Invalid token') {
              window.localStorage.removeItem('accessTokenAdm');
              window.location.reload();
            }
          }
          // this.token = resp.token;
        }
      )

  }
  getSingleModel(resolve, reject, {id, type, name} ) {
    // get 3d_model
    // formData.append('user_query', JSON.stringify( {mode:"model",action:"get",data:{id:1}, token} ));

    // get 3d_model_type
    // formData.append('user_query', JSON.stringify( {mode: "model_type", action:"get", data: {type:1,level:1}, token} ));
    // objState.objDataArr.forEach( model => {});
    // console.log( 'model', model );
    // let {name, type, image, level, params} = model; 
    // type:"vanity"

    // let modelTypeId = modelTypeName ? this.modelTypes[modelTypeName] : typeId;

    let data = {};
    if (id) data.id = id;
    if (type) data.type = type;
    if (name) data.name = name;

    // console.log( 'model data', data );

      this.manageListData(
        'POST', null,
        { mode:'model', action:'get', data, token: this.token },
        resp => {

          if ( resp.state === 'success' && resp.data) {
            // resp.data.forEach( model => {});
            if (resolve) resolve(resp.data[0]);
          } else if (reject) reject(resp);
          // this.token = resp.token;
        }
      )

  }
  addModelTypesToDB(typeData) {

    if (!typeData) typeData = {};
    let {id, name, image, level} = typeData;
    
      this.manageListData(
        'POST', null,
        { mode:'model_type',action:'add',data:{id: id || 0, name: name || '', image: image || '', level: level || 1}, token: this.token },
        resp => {
          console.log( 'addModelTypes', resp );
          
          if ( resp.state === 'success' && resp.data) {
            // resp.data.forEach( model => {});
          }
          // this.token = resp.token;
        }
      );

  }
  getModelTypesFromDB(resolve, reject) {

      this.manageListData(
        'POST', null,
        { mode:'model_type',action:'get',data:{}, token: this.token },
        resp => {
          console.log( 'getModelTypes', resp );
          
          if ( resp.state === 'success' && resp.data) {
            // resp.data.forEach( model => {});


            
            if (resolve) resolve(resp.data);
          } else if (reject) reject(resp);

          // this.token = resp.token;
        }
      );

  }

  addMaterialToDB(resolve, reject, material) {

      this.manageListData(
        'POST', null, { mode:'material', action:'add', data: material, token: this.token },
        resp => {
          console.log( 'addMaterial success', resp );
          if ( resp.state === 'success' && resp.data) {
            resolve(resp.data.id);
          } else {
            resolve(false);
          }
        }
      );

  }
  getMaterialsFromDB(resolve, reject, type, price) {

    let filters = {};
    if (type) filters.type = type;
    // if (price) filters.price = price;

    let body = { mode:'material', action:'get', data: filters, token: this.token };

    this.manageListData(
      'POST', null, body, resp => {
        // console.log( 'getMaterial serv', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data);
        } else reject(false);

        // this.token = resp.token;
      }
    );

  }
  getSingleMaterial(resolve, reject, { id, type, name } ) {
    // id:0,type:"1",level:""
    // console.log('materialTypeId sf', materialTypeId);
    let data = {};
    if (id) data.id = id;
    if (type) data.type = type;
    if (name) data.name = name;

    // console.log( 'mat data', data );

    this.manageListData(
      'POST', null,
      { mode:'material',action:'get',data, token: this.token },
      resp => {
        // console.log( 'getSingleMaterial serv', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          resolve(resp.data[0]);
        } else reject(false);

        // this.token = resp.token;
      }
    );

  }
  addMaterialTypesToDB(typeData) {

    if (!typeData) typeData = {};
    let {id, name, level, image} = typeData;
    
    this.manageListData(
      'POST', null,
      { mode:'material_type',action:'add',data:{id: id || 0, name: name || '', image: image || '', level: level || 1}, token: this.token },
      resp => {
        console.log( 'addMaterialTypes', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
        }
        // this.token = resp.token;
      }
    );

  }
  getMaterialTypesFromDB(resolve, reject) {
    // id:0, type:"1", image:"", level:""
    this.manageListData(
      'POST', null,
      { mode:'material_type',action:'get',data:{}, token: this.token },
      resp => {
        console.log( 'getMaterialTypes', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {});
          if (resolve) resolve(resp.data);
        } else if (reject) reject(resp);

      }
    );

  }

  changeMaterial(resolve, reject, material) {
    // addMaterialToDB(resolve, reject, material)
    if (!material.id) resolve(false);

    this.manageListData(
      'POST', null, { mode:'material', action:'delete', data: {id: material.id }, token: this.token },
      resp => {
        console.log( 'changeMaterial', resp );
        // if ( resp.state === "success" && resp.data) {
          // resp.data.forEach( model => {} );
          this.addMaterialToDB(resolve, reject, material)
        // }
        // this.token = resp.token;
      }
    );
  }
  deleteMaterial(resolve, reject, { id } ) {

    // const type = this.modelTypes[category];, type , category 

    this.manageListData(
      'POST', null,
      { mode:'material', action: 'delete', data: { id }, token: this.token },
      resp => {
   
        if (resp.state === 'success') {
          resolve(true);
        } else {
          // console.error( 'model hange Error', resp );
          resolve(false);
        }
        // this.token = resp.token;
      }
    );

  }

  changeMaterialType(matType) {

    this.manageListData(
      'POST', null,
      { mode:'material_type', action:'delete', data: {id: matType.id || 1}, token: this.token },
      resp => {
        console.log( 'changeMatType', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {} );
          this.addMaterialTypesToDB(matType);
        }
        // this.token = resp.token;
      }
    );

  }
  changeModel(resolve, reject, model, isReturn) {

    if (!model.id) {
      resolve(false);
      return;
    }
    // console.log( 'model', model );
    // const type = this.modelTypes[model.category] || this.modelTypes[model.type]; 
// , type: model.type
    this.manageListData(
      'POST', null,
      { mode:'model', action:'delete', data: { id: model.id }, token: this.token },
      resp => {
        // console.log( 'changeModel', resp, resp.state === "success" );
        if (resp.state === 'success') {
          // resp.data.forEach( model => {} );
          // console.log( 'addModelToDB' );
          this.addModelToDB(resolve, reject, model);
        } else {
          console.error( 'model hange Error', resp );
          resolve(false);

          if (resp.error_desc === 'Invalid token') {
            window.localStorage.removeItem('accessTokenAdm');
            window.location.reload();
          }
        }
        // this.token = resp.token;
      }
    );

  }
  deleteModel(resolve, reject, { id } ) {

    // const type = this.modelTypes[category];, type , category 

    this.manageListData(
      'POST', null,
      { mode:'model', action:'delete', data: { id }, token: this.token },
      resp => {
        // console.log( 'deleteModel', resp  );
        if (resp.state === 'success') {
          // resp.data.forEach( model => {} );
          // console.log( 'addModelToDB' );
          // this.addModelToDB(resolve, reject, model);
          resolve(true);
        } else {
          console.error( 'model hange Error', resp );
          resolve(false);
        }
        // this.token = resp.token;
      }
    );

  }
  changeModelType(modelType) {

    this.manageListData(
      'POST', null,
      { mode:'model_type', action:'delete', data: {id: modelType.id || 1}, token: this.token },
      resp => {
        console.log( 'changeModelType', resp );
        
        if ( resp.state === 'success' && resp.data) {
          // resp.data.forEach( model => {} );
          this.addModelTypesToDB(modelType);
        }
        // this.token = resp.token;
      }
    );

  }

  delMaterials() {

    // for(let i = 23; i<24; i++) {

    //   this.manageListData(
    //     "POST", null,
    //     { mode:"material", action:"delete", data: {id: i}, token: this.token },
    //     resp => {
    //       console.log( 'changeMaterial', resp );
          
    //       if ( resp.state === "success" && resp.data) {
    //         // resp.data.forEach( model => {} );
    //         // addMaterialToDB(matElem);
    //       }
    //       // this.token = resp.token;
    //     }
    //   );

    // }

  }
  // delModels(s, e) {

  //   if (!s) s = 0;
  //   if (!e) e = -1;

  //   for(let i = s; i<=e; i++) {

  //     this.manageListData(
  //       "POST", null,
  //       { mode:"model", action:"delete", data: {id: i}, token: this.token },
  //       function respond(resp) {
  //         console.log( 'delModel', resp );
          
  //         if ( resp.state === "success" && resp.data) {
  //           // resp.data.forEach( model => {} );
  //           // addMaterialToDB(matElem);
  //         }

  //         // that.token = resp.token;
  //       }
  //     );

  //   }

  // }


}