import { USER_ROLE } from './types/user';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const MAX_ORDERS_PER_DAY = 10;
export const POWDER_ROOM_WORK_DURATION_DAYS = 14; // powder bathrooms work duration
export const OTHER_DURATION_DAYS = 30; // other bathrooms work duration

export enum WorkRequestItemType {
  STANDARD = 'STANDARD',
  ALL_ORDERS = 'ALL_ORDERS',
}

export const WORK_REQUEST_TYPE_LABEL = {
  [WorkRequestItemType.STANDARD]: 'Standard',
  [WorkRequestItemType.ALL_ORDERS]: 'All Orders',
}

export const ADMIN_ACCESS_USER_ROLES = [
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.ADMIN,
  USER_ROLE.PROJECT_MANAGER,
  USER_ROLE.SCHEDULER,
  USER_ROLE.DESIGNER,
  USER_ROLE.EXCEL,
  USER_ROLE.SALER,
  USER_ROLE.AR,
]

export const DEFAULT_CURRENCY = 'CAD';
export const DEFAULT_TAX_RATE = 0.13;

export const YES = 'YES';
export const NO = 'NO';

export const BATHROOM_DISCOUNT = 3000;

export const ORDER_LIST_PATH = '/dashboard/orders';

export const USER_ROLES = [
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.ADMIN,
  USER_ROLE.PROJECT_MANAGER,
  USER_ROLE.SCHEDULER,
  USER_ROLE.DESIGNER,
  USER_ROLE.EXCEL,
  USER_ROLE.SALER,
  USER_ROLE.USER,
  USER_ROLE.AR,
]

export const ADMIN_USER_ROLES = [
  USER_ROLE.SUPER_ADMIN,
  USER_ROLE.ADMIN,
  USER_ROLE.PROJECT_MANAGER,
  USER_ROLE.SCHEDULER,
  USER_ROLE.DESIGNER,
  USER_ROLE.EXCEL,
  USER_ROLE.SALER,
  USER_ROLE.AR,
]

export const CUSTOMER_USER_ROLES = [
  USER_ROLE.USER,
]

export const localStorageKeys = {
  content: 'content',
  payload: 'payload',
  splitContent: 'splitted-content'
};

export const fontSizes = ['8', '9', '10', '11', '12', '14', '18', '24', '30', '36', '48', '60', '72', '96'];

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}