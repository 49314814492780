import { createSlice } from '@reduxjs/toolkit';
import { User } from 'src/types/user';

export type AuthState = {
  isAuthenticated: boolean,
  isInitialized: boolean,
  currentUser: User | null,
}

export const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  currentUser: null,
};


const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    init(state, action) {
      const { isAuthenticated, user } = action.payload;
      state.isInitialized = true;
      state.isAuthenticated = isAuthenticated;
      state.currentUser = user;
    },
    login(state, action) {
      state.isAuthenticated = true;
      state.currentUser = action.payload.user;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.currentUser = null;
    },
    register(state, action) {
      state.isAuthenticated = true;
      state.currentUser = action.payload.user;
    }
  },
});

export const {
  init,
  login,
  logout,
  register,
} = authSlice.actions

export const authReducer = authSlice.reducer;

// const handlers = {
//   INITIALIZE: (state, action) => {
//     const { isAuthenticated, user, server } = action.payload;

//     return {
//       ...state,
//       isAuthenticated,
//       isInitialized: true,
//       currentUser: user,
//       server,
//     };
//   },
//   LOGIN: (state, action) => {
//     const { user, server } = action.payload;

//     return {
//       ...state,
//       isAuthenticated: true,
//       currentUser: user,
//       server,
//     };
//   },
//   LOGOUT: (state) => ({
//     ...state,
//     isAuthenticated: false,
//     currentUser: null,
//     server: null,
//   }),
//   REGISTER: (state, action) => {
//     const { user } = action.payload;

//     return {
//       ...state,
//       isAuthenticated: true,
//       currentUser: user,
//     };
//   },
// };

// const reducer = (state, action) =>
//   handlers[action.type] ? handlers[action.type](state, action) : state;