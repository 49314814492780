import { useDispatch as useReduxDispatch, useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { setStore } from './getStore';

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true'
});

setStore(store);


export const useDispatch = () => useReduxDispatch();

export default store;

export type AppState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<AppState>  = useReduxSelector;
