import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
// import SettingsDrawer from './components/SettingsDrawer';

import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';

const useStyles = makeStyles({
  snackbarRoot: {
    pointerEvents: 'auto',
  }
})

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  const classes = useStyles();

  useScrollReset();


  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        dense
        iconVariant={{
          error: null,
        }}
        maxSnack={4}
        classes={{
          root: classes.snackbarRoot,
        }}
        anchorOrigin={{
          horizontal: 'right', 
          vertical: 'top',
      }}
      >
        <GlobalStyles />
        {/* <SettingsDrawer /> */}
        {auth.isInitialized ? content : <SplashScreen />}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
