import { createSlice, PayloadAction, Action } from '@reduxjs/toolkit';
import { ValidationResult } from 'src/components/dashboard/customer/CustomerOrderDetails/validation';

export type OrderDetailsState = {
  selectedBathroomIndex: number | string,
  workRequestOpen: boolean,

  validation: {
    errorOpen: boolean,
    selectedErrorIndex: number,
    result?: ValidationResult,
  },
}

export const initialState: OrderDetailsState = {
  selectedBathroomIndex: 0,
  workRequestOpen: false,

  validation: {
    errorOpen: false,
    selectedErrorIndex: 0,
    result: undefined,
  }
}

const orderDetailsSlice = createSlice({
  initialState,
  name: 'orderDetails',
  reducers: {
    resetOrderDetailsState(state, action: Action) {
      return initialState;
    },
    setSelectedBahroomIndex(state, action: PayloadAction<number | string>) {
      state.selectedBathroomIndex = action.payload;
    },
    setWorkRequestOpen(state, action: PayloadAction<boolean>) {
      state.workRequestOpen = action.payload;
    },
    openValidationError(state, action: Action) {
      state.validation.errorOpen = true;
      state.validation.selectedErrorIndex = 0;
      state.validation.result = undefined;
    },
    closeValidationError(state, action: Action) {
      state.validation.errorOpen = false;
    },
    setValidationSelectedErrorIndex(state, action: PayloadAction<number>) {
      state.validation.selectedErrorIndex = action.payload;
    },
    setValidationResult(state, action: PayloadAction<ValidationResult>) {
      state.validation.result = action.payload;
      
      const lastErrorIndex = action.payload.errors.length - 1;
      if (lastErrorIndex < state.validation.selectedErrorIndex) {
        state.validation.selectedErrorIndex = lastErrorIndex;
      }
    },
  }
})

export const {
  resetOrderDetailsState,
  setSelectedBahroomIndex,
  setWorkRequestOpen,
  openValidationError,
  closeValidationError,
  setValidationSelectedErrorIndex,
  setValidationResult,
} = orderDetailsSlice.actions

export const orderDetailsReducer = orderDetailsSlice.reducer