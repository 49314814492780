import { PERMISSION_ENTITY, usePermissions } from 'src/hooks/usePermissions';
import { PermissionAccessError } from './PermissionAccessError';

type Props = {
  permissions: PERMISSION_ENTITY,
}

const PermissionsGuard: React.FC<Props> = (props) => {
  const { children, permissions } = props;
  const { canView } = usePermissions();

  if (!canView(permissions)) {
    return <PermissionAccessError />
  }

  return children as any;
};


export default PermissionsGuard;
