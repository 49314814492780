import { PaintColor, PAINT_COLOR_PLACEMENT } from './paintColor';
import { BATHROOM_TYPE, BATHROOM_TYPE_NAME, EMAIL_STATUS } from './generic';
import { Material, MATERIAL_PLACEMENT } from './material';
import { Model } from './model';
import { Option } from './option';
import { Invoice } from './invoice';
import { ProjectManager, User } from './user';
import { WorkRequestItem } from './workRequestItem';
import { PaypalPayment } from './paypalPayment';
import { Contractor, Subcontractor } from './contractor';
import { Grout } from './grout';
import { WorkRequest } from './workRequest';

export type OrderProductSection =
  | 'Accessories'
  | 'Bathroom'
  | 'bathtub'
  | 'Bathtub faucet'
  | 'Free standing bathtub faucet'
  | 'toilet'
  | 'vanity'
  | 'Vanity light'
  | 'Vanity mirror'
  | 'Wall paint'
  | 'Bathroom floor tiles';

export type OrderProduct = {
  section: OrderProductSection;
  name: string;
  level: string;
  type: string;
  price: string;
};

export type BathroomType = 'master' | 'nonMaster' | 'powderRoom';

export type BathroomData = {
  productsList: OrderProduct[];
  basePrice: number;
  extraPayment: number;
  bathroomType: BathroomType;
};

export type OrderDate = {
  day: number;
  month: number;
  year: number;
};

export enum ORDER_STATUS {
  NEW = 'NEW',
  READY_TO_START = 'READY_TO_START',
  COMPLETED = 'COMPLETED',
}

export type Order = {
  id: string,
  orderNumber: string,
  date: string, // "2021-06-23 20:13:18"
  userId: string,
  designerId: string,
  showRoomDate: string, // '2021-06-25'
  showRoomDateMs: number,
  startDate: string; // '2021-06-25'
  sentToSchedulerDate?: string, // "2021-06-23 20:13:18"
  completedDate?: string, // "2021-06-23 20:13:18"
  bathrooms: Bathroom[],
  status: ORDER_STATUS,
  address: string,
  qboEstimateId: string,
  propertyType: PROPERTY_TYPE,
  taxRate?: TaxRate,
  payment?: PaypalPayment,
  contractor?: Contractor,
  subcontractor?: Subcontractor,
  projectManager?: ProjectManager,
  projectManagerEmailStatus: EMAIL_STATUS,
  workRequest: WorkRequest | null;
  finalQuote: Quote,
  contractS3Key?: string,
  readonly: boolean, // calculated on the frontend during order parsing
}

export type OrderWithUser = Order & {
  user: User;
};

export enum PROPERTY_TYPE {
  HOUSE = 'HOUSE',
  CONDO = 'CONDO',
}

export type PurchaseOrder = {
  id: string,
  bathroomId: string,
  supplierId: string,
  poNumber: string,
  state: EMAIL_SENDING_STATE,
  createdAt: string, // 2021-07-21 09:10:27
  sentAt: string, // 2021-07-21 09:10:27
}

export enum EMAIL_SENDING_STATE {
  NONE = 'NONE',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SENT = 'SENT',
}

export type OrderListState = {
  list: null | Order[];
  loading: boolean;
  error: any;
};

export type OrderDBEntity = {
  id: string;
  contractorId: string;
  subcontractorId: string;
  projectManagerId: string;
};

export type OrderShowroomAvailability = {
  unavailableDates: OrderDate[];
  unavailableDatesStrFormat: string[]; // dates in format 'yyyy.mm.dd'
};

export type Bathroom = {
  id: string;
  name: string;
  type: BATHROOM_TYPE;
  colors: PaintColor[];
  materials: BathroomMaterial[];
  models: Model[];
  options: Option[];
  basePrice: number;
  extraPayment: number;
  discount: number,
  invoices: Invoice[];
  purchaseOrders?: PurchaseOrder[],
  requiredOptionIds: string[],
};

export type BathroomMaterial = Material & {
  groutInfo?: GroutInfo;
};

export type Quote = {
  id: string,
  orderId: string,
  userId: string,
  createdAt: string, // 2021-07-31 17:37:40
  state: QUOTE_STATE,
  type: QUOTE_TYPE,
}

export enum QUOTE_STATE {
  FAILED = 'FAILED',
  NEW = 'NEW',
  PENDING = 'PENDING',
  SENT = 'SENT',
}

export enum QUOTE_TYPE {
  INITIAL = 'INITIAL',
  FINAL = 'FINAL',
}

export type GroutInfo = {
  id: 'string';
  quantity: number;
  grout: Grout;
};

export type TaxRate = {
  id: string;
  name: string;
  label: string;
  rate: number;
};

export type OrderWorkRequestItem = WorkRequestItem & {
  quantity: number;
  state: ORDER_WORK_REQUEST_ITEM_STATE;
};

export enum ORDER_WORK_REQUEST_ITEM_STATE {
  NONE = 'NONE',
  DELETED = 'DELETED',
}

export enum PRODUCT_TYPE {
  OPTION = 'option',
  MATERIAL = 'material',
  MODEL = 'model',
  PAINT_COLOR = 'paintColor',
}

export enum DATE_TARGET {
  START_DATE = 'startDate',
  SHOW_ROOM_DATE = 'showRoomDate',
}

export type NewBathroomData = {
  type: BATHROOM_TYPE_NAME, 
  userSelectedType: BATHROOM_TYPE_NAME, 
  piecesNumber: number | string, // 2 | 3 | 4 | 'bidet' ... -> don't ask me why :)
  items: ProductsOrderListRaw, 
  discount: number,
}

export type ProductsOrderListRaw = {
  idOptions: any[],
  idModels: {
    id: string,
    key: string,
  },
  idMaterials: {
    id: string,
    key: string,
    materialPlacement: MATERIAL_PLACEMENT,
  },
  idColors: {
    id: string,
    key: string,
    colorPlacement: PAINT_COLOR_PLACEMENT,
  },
}

// export type OrderContractor = Omit<Contractor, 'subcontractors'> & {
//   subcontractor: Subcontractor,
// }
