
const TORONTO_CONFIG = {
  BASE_URL: 'https://api.bathroomrenovations2.com',
  SERVER_NAME: 'TORONTO',
  GENERIC_ENDPOINT: '/',
}

const OTTAWA_CONFIG = {
  BASE_URL: 'https://api.bathroomrenovations2.com',
  SERVER_NAME: 'OTTAWA',
  GENERIC_ENDPOINT: '/',
}

const DEV_CONFIG = {
  BASE_URL: 'https://apidev.bathroomrenovations2.com',
  SERVER_NAME: 'DEV_TORONTO',
  GENERIC_ENDPOINT: '/',
}

const LOCAL_CONFIG = {
  BASE_URL: 'http://localhost:3000',
  SERVER_NAME: 'LOCAL',
  GENERIC_ENDPOINT: '/',
}

export const IS_DEV = window.location.hostname === 'localhost';

const isTor = window.location.host.match('admin.toronto');
const isOtt = window.location.host.match('admin.ottawa');
const isDev = window.location.host.match('development');
const SERVER_NAME_SAVED = localStorage.getItem('SERVER_NAME'); 

// let CONFIG = TORONTO_CONFIG;
let CONFIG = isTor ? TORONTO_CONFIG :
             isOtt ? OTTAWA_CONFIG :
             isDev ? DEV_CONFIG :
             DEV_CONFIG;

if (window.location.host.match('localhost') && SERVER_NAME_SAVED) {
  switch(SERVER_NAME_SAVED) {
    case 'TORONTO':
      CONFIG = TORONTO_CONFIG;
      break;
    case 'OTTAWA':
      CONFIG = OTTAWA_CONFIG;
      break;
    case 'DEVELOPMENT':
      CONFIG = DEV_CONFIG;
      break;
   case 'LOCAL':
      CONFIG = LOCAL_CONFIG;
      break;
    default:
      CONFIG = LOCAL_CONFIG;
  }
}

export const AVAILABLE_ENV = [
  { label: 'Bathrooms', value: 'bathrooms' }, 
  { label: 'Kitchens',  value: 'kitchens' }, 
  { label: 'Basements', value: 'basements' }
];
export const DEFAULT_TAX_RATE = 'ONTARIO';
export const AVAILABLE_REGIONS = [
  { label: 'GTA Region', value: 'ONTARIO' }, 
  { label: 'Greater Ottawa Region', value: 'ONTARIO' },
  { label: 'Metro Vancouver', value: 'BRITISH_COLUMBIA' },
  { label: 'Calgary Metropolitan Area', value: 'ALBERTO' },

  { label: 'Chicago Metropolitan Area', value: 'CHICAGO', increaseBy: 5 }, 
  { label: 'Denver Metropolitan Area', value: 'DENVER', increaseBy: 5 },
  { label: 'San Francisco Metropolitan Area', value: 'SAN_FRANCISCO', increaseBy: 5 },
  { label: 'Washington DC Metropolitan Area', value: 'WASHINGTON_DC', increaseBy: 5 },
];

export const ADMIN_BASE_URL = CONFIG.BASE_URL;
export const APP_SERVER_NAME = CONFIG.SERVER_NAME;
export const GENERIC_ENDPOINT = CONFIG.GENERIC_ENDPOINT;

export const adminEndPoint = `${ADMIN_BASE_URL}${GENERIC_ENDPOINT}`;
export const APP_SERVER_NAME_HEADER = 'app-server-name';

export const enpFileUpload = 'https://files.agmbathroomtest.com/upload_file.php';
export const enpFileRemove = 'https://files.agmbathroomtest.com/delete_file.php';

export const enpContractUpload = 'https://files.agmbathroomtest.com/upload_contract.php';
export const enpContractRemove = 'https://files.agmbathroomtest.com/delete_contract.php';

export const tabs = [
    {
      label: 'Plumbing Fixtures',
      value: 'models'
    },
    {
      label: 'Tiles',
      value: 'materials'
    },
    {
      label: 'Paint Colors',
      value: 'paintColors'
    },
    {
      label: 'Levels 2&3',
      value: 'options'
    },
];

export const assets_tabs = [
  {
    label: '3D Models',
    value: 'models'
  },
  {
    label: 'Materials',
    value: 'materials'
  },
  {
    label: 'Categories',
    value: 'categories'
  }
];

export const tabsCustomer = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Booked',
    value: 'hasAcceptedMarketing'
  },
  {
    label: 'Not Booked',
    value: 'isNotBooked'
  },
  {
    label: 'Complete',
    value: 'isComplete'
  },
  {
    label: 'Returned',
    value: 'isReturned'
  }
];
  
export const levels = [
    {
      label: '1',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    },
    {
      label: '3',
      value: '3'
    }
];
  
export const surfaces = [
  {
    label: 'Matte',
    value: 'matte'
  },
  {
    label: 'Satin',
    value: 'satin'
  },
  {
    label: 'Polished',
    value: 'polished'
  }
];

export const tilesSizes = {
  tiles: [
    {
      label: '12" x 24"',
      value: '12x24'
    },
    {
      label: '24" x 24"',
      value: '24x24'
    }
  ],
  'wall tiles': [
    {
      label: '12" x 24"',
      value: '12x24'
    },
    {
      label: '24" x 24"',
      value: '24x24'
    }
  ],
  mosaics: [
    {
      label: 'Small',
      value: 'small'
    }
  ],
  hexagons: [
    {
      label: 'Small',
      value: 'small'
    }
  ]
};

export const inventoryOptions = [
    {
      label: 'In Stock',
      value: 'in_stock'
    },
    {
      label: 'Limited',
      value: 'limited'
    },
    {
      label: 'Out of Stock',
      value: 'out_of_stock'
    }
];

export const availabilityOptions = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Available',
    value: 'available'
  },
  {
    label: 'Unavailable',
    value: 'unavailable'
  }
];

const padding = 30;
export const PDF_PAGE_MAX_HEIGHT = 1469 - padding;