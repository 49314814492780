import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from '../lib/axios';
import { verify, JWT_SECRET } from '../utils/jwt';
import {
  adminEndPoint,
  APP_SERVER_NAME,
  APP_SERVER_NAME_HEADER,
} from '../constants/server';
import ServerService from '../ServerService';
import { fetchCurrentUser } from 'src/api/userApi';
import { ADMIN_ACCESS_USER_ROLES } from 'src/constants';
import { useSelector } from 'src/store';
import { useDispatch } from 'react-redux';
import * as authActions from 'src/slices/authSlice';

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenAdm');
    delete axios.defaults.headers.common.Authorization;
  }
};

const specialAccess = [
  {
    email: 'excel@agm.com',
    role: 7,
  }
];

const AuthContext = createContext({
  isAuthenticated: false,
  isInitialized: false,
  currentUser: null,
  server: null,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [ server, setServer ] = useState(null);
  const authState = useSelector(state => state.auth)
  const dispatch = useDispatch();
  // console.error( 'AuthProvider', state, reducer );
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const accessTokenAdm = window.localStorage.getItem('accessTokenAdm');

        if (accessToken && verify(accessToken, JWT_SECRET)) {
          setSession(accessToken);

          const userTYPE = await fetchCurrentUser();
          const user = JSON.parse( JSON.stringify(userTYPE) );

          const specialUser = specialAccess.find( userSp => userSp.email === user.email );
          if (specialUser) { 
            user.role = specialUser.role; 
          }
          // const server = null;
          const server = new ServerService({
            token: accessTokenAdm,
            url: adminEndPoint,
          });
          const isInit = await new Promise((resolve, reject) =>
            server.modelTypesInit(resolve, reject)
          );
          await new Promise((resolve, reject) =>
            server.materialTypesInit(resolve, reject)
          );
          // console.error( 'user', server, accessTokenAdm );

          if (isInit) {
            dispatch(authActions.init({ isAuthenticated: true, user }));
            setServer(server);
          } else {
            setSession(null);
            dispatch(authActions.logout());
            setServer(null);
            window.location.reload();
          }
        } else {
          dispatch(authActions.init({ isAuthenticated: false, user: null }));
          setServer(null);
        }
      } catch (err) {
        // console.error(err);
        dispatch(authActions.init({ isAuthenticated: false, user: null }))
        setServer(null);
      }
    };

    initialize();
  }, [dispatch]);

  const login = async (email, password, em, pass) => {



    if (!em) {
      if (window.location.host.match('localhost')) {
        em = 'admin.models3@agm.com';
      } else {
        return null;
      }
    }

    if (!pass) {
      if (window.location.host.match('localhost')) {
        pass = 'agmbathrooms2021qwezxc';
      } else {
        return null;
      }
    }

    const response = await axios.post('/api/authentication/login', {
      email,
      password,
    });


    var formData = new FormData();
    formData.append(
      'user_query',
      JSON.stringify({
        mode: 'user',
        action: 'auth',
        data: { email: em, pas: pass },
        token: '',
      })
    );

    var authData = await fetch(adminEndPoint, {
      method: 'POST',
      headers: {
        [APP_SERVER_NAME_HEADER]: APP_SERVER_NAME,
      },
      body: formData,
    });
    var authDataJson = await authData.json();
    // const authDataJson = {};
    // console.error( 'response auth', response, authDataJson, authDataJson.error_desc );

    const { accessToken, user } = response.data;

    if (authDataJson.error_desc) {
      return { error: authDataJson.error_desc};
    }

    if (!ADMIN_ACCESS_USER_ROLES.includes(authDataJson.role.toString())) {
      return { error: "You don't have permissions to login to admin dashboard"};
    }
   
    const specialUser = specialAccess.find( user => user.email === em );
    if (specialUser) { authDataJson.role = specialUser.role; }

    user['role'] = authDataJson.role;
    user['id'] = authDataJson.id;

    const server = new ServerService({
      token: authDataJson.token,
      url: adminEndPoint,
    });
    await new Promise((resolve, reject) =>
      server.modelTypesInit(resolve, reject)
    );
    await new Promise((resolve, reject) =>
      server.materialTypesInit(resolve, reject)
    );
    localStorage.setItem('accessTokenAdm', authDataJson.token);

    setSession(accessToken);
    dispatch(authActions.login({ user }))
    setServer(server);
  };

  const logout = async () => {
    setSession(null);
    dispatch(authActions.logout());
    setServer(null);
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/authentication/register', {
      email,
      name,
      password,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(authActions.register({ user }))
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        server,
        platform: 'JWT',
        login: login as any,
        logout,
        register: register as any,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
