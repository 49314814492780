import { USER_ROLE } from '../types/user'
import useAuth from './useAuth'

export enum PERMISSION_ACTION {
  'VIEW' = 'VIEW',
  'ADD' = 'ADD',
  'UPDATE' = 'UPDATE',
  'DELETE' = 'DELETE',
}

export const usePermissions = () => {
  const { currentUser } = useAuth();

  const getPermission = (entity: Object | boolean, action: PERMISSION_ACTION): boolean => {
    if (!entity || !action) {
      return false;
    }

    return typeof entity === 'boolean' ? entity : entity[action];
  }

  const canView = (entity: PERMISSION_ENTITY) => {
    return getPermission(PERMISSION[currentUser.role][entity], PERMISSION_ACTION.VIEW) ;
  }

  const canAdd = (entity: PERMISSION_ENTITY) => {
    return getPermission(PERMISSION[currentUser.role][entity], PERMISSION_ACTION.ADD) ;
  }

  const canUpdate = (entity: PERMISSION_ENTITY) => {
    return getPermission(PERMISSION[currentUser.role][entity], PERMISSION_ACTION.UPDATE) ;
  }

  const canDelete = (entity: PERMISSION_ENTITY) => {
    return getPermission(PERMISSION[currentUser.role][entity], PERMISSION_ACTION.DELETE) ;
  }

  return { 
    canView, 
    canAdd, 
    canUpdate, 
    canDelete,
    isDesigner: currentUser.role === USER_ROLE.DESIGNER,
    isAR: currentUser.role === USER_ROLE.AR,
    isSuperAdmin: currentUser.role === USER_ROLE.SUPER_ADMIN,
  }
}

export enum PERMISSION_ENTITY {
  'ASSET' = 'ASSET',
  'USER' = 'USER',
  'CUSTOMER' = 'CUSTOMER',
  'STATISTICS' = 'STATISTICS',
  'PRODUCT' = 'PRODUCT',
  'PRODUCT_UPGRADE' = 'PRODUCT_UPGRADE',
  'SUPPLIER' = 'SUPPLIER',
  'CONTRACTOR' = 'CONTRACTOR',
  'SUBCONTRACTOR' = 'SUBCONTRACTOR',
  'ORDER' = 'ORDER',
  'SALER' = 'SALER',
  'EMAIL_CAMPAIGN' = 'EMAIL_CAMPAIGN',
  'WORK_REQUEST' = 'WORK_REQUEST',
  'PURCHASE_ORDER' = 'PURCHASE_ORDER',
  'SETTINGS' = 'SETTINGS',
}

type PERMISSION_MAP = Record<PERMISSION_ENTITY, boolean | Record<PERMISSION_ACTION, boolean>>;

const PERMISSIONS_ALL = (value) => (
  {
    ASSET: value,
    USER: value,
    CUSTOMER: value,
    STATISTICS: value,
    PRODUCT: value,
    PRODUCT_UPGRADE: value,
    SUPPLIER: value,
    CONTRACTOR: value,
    SUBCONTRACTOR: value,
    WORK_REQUEST: value,
    PURCHASE_ORDER: value,
    SETTINGS: value,
    EMAIL_CAMPAIGN: false,
    SALER: value,
    ORDER: {
      VIEW: value,
      ADD: value,
      UPDATE: value,
      DELETE: value,
    },
    
  }
)

// const PERMISSIONS_ALL2: PERMISSION_MAP = {
//   USER: true,
//   CUSTOMER: true,
//   PRODUCT: true,
//   PRODUCT_UPGRADE: true,
//   SUPPLIER: true,
//   CONTRACTOR: true,
//   SUBCONTRACTOR: true,
//   WORK_REQUEST: true,
//   PURCHASE_ORDER: true,
//   SETTINGS: true,
//   EMAIL_CAMPAIGN: false,
//   ORDER: {
//     VIEW: true,
//     ADD: true,
//     UPDATE: true,
//     DELETE: false,
//   }
// }

const SUPER_ADMIN_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(true),
  EMAIL_CAMPAIGN: true,
}

const ADMIN_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(true),
  EMAIL_CAMPAIGN: true,
  ASSET: false,
  USER: {
    VIEW: true,
    ADD: false,
    UPDATE: false,
    DELETE: false,
  },
}

const PROJECT_MANAGER_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(true),
  ASSET: false,
  USER: false,
  SALER: false,
  PRODUCT: false,
  PRODUCT_UPGRADE: false,
  SUPPLIER: true,
  CONTRACTOR: true,
  SUBCONTRACTOR: true,
  SETTINGS: false,
  WORK_REQUEST: false,
  PURCHASE_ORDER: false,
}

const SCHEDULER_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(true),
  ASSET: false,
  USER: false,
  SALER: false,
  PRODUCT: false,
  PRODUCT_UPGRADE: false,
  SUPPLIER: true,
  CONTRACTOR: true,
  SUBCONTRACTOR:  false,
  SETTINGS: false,
  WORK_REQUEST: false,
  PURCHASE_ORDER: false,
}

const DESIGNER_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(true),
  ASSET: false,
  USER: false,
  SALER: false,
  PRODUCT: true,
  PRODUCT_UPGRADE: true,
  SUPPLIER: false,
  CONTRACTOR: false,
  SUBCONTRACTOR: false,
  SETTINGS: false,
  WORK_REQUEST: false,
  PURCHASE_ORDER: false,
}

const EXCEL_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(false),
  CUSTOMER: true,
}

const SALER_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(false),
  SALER: true,
}


const AR_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(false),
  CUSTOMER: true,
  ORDER: {
    VIEW: true,
    ADD: false,
    UPDATE: false,
    DELETE: false,
  },
}

const ASSET_PERMISSION_MAP: PERMISSION_MAP = {
  ...PERMISSIONS_ALL(false),
  ASSET: true,
}

export const PERMISSION = {
  [USER_ROLE.SUPER_ADMIN]: SUPER_ADMIN_PERMISSION_MAP,
  [USER_ROLE.ADMIN]: ADMIN_PERMISSION_MAP,
  [USER_ROLE.PROJECT_MANAGER]: PROJECT_MANAGER_PERMISSION_MAP,
  [USER_ROLE.SCHEDULER]: SCHEDULER_PERMISSION_MAP,
  [USER_ROLE.DESIGNER]: DESIGNER_PERMISSION_MAP,
  [USER_ROLE.EXCEL]: EXCEL_PERMISSION_MAP,
  [USER_ROLE.SALER]: SALER_PERMISSION_MAP,
  [USER_ROLE.AR]: AR_PERMISSION_MAP,
  [USER_ROLE.ASSET]: ASSET_PERMISSION_MAP
}