export type User = {
  id: string,
  name: string,
  lastName: string,
  email: string,
  phone: string,
  address?: string,
  role: USER_ROLE,
  createdDate: string,
  fullName: string,
}

export type UserListState = {
  list: User[],
  loading: boolean,
  error: any,
}

export type UserEdit = Omit<Omit<User, 'createdDate'>, 'fullName'>

export type NewUser = Omit<UserEdit, 'id'> & {
  password: string,
}

export enum USER_ROLE {
  SUPER_ADMIN = '10',
  ADMIN = '5',
  SCHEDULER = '4',
  PROJECT_MANAGER = '3',
  DESIGNER = '2',
  USER = '1',
  EXCEL = '7',
  SALER = '8',
  AR = '12',
  ASSET = '9',
}

export type ProjectManager = User;

export type UsersStats = {
  usersCount: number,
  desktop: DeviceStats,
  tablet: DeviceStats,
  mobile: DeviceStats
}

export type DeviceStats = {
  usersCount: number,
  stepsStats: StepStats,
}

export type StepStats= {
  bookStartDate: number,
  seeYourQuote: number,
  selectingFinishes: number,
  currentLayout: number,
  dimensions: number,
  bathroomType: number,
}
