import { Formik } from 'formik';
// Alert,
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';

const LoginJWT = (props) => {
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  return (
    
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}

      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const result = await login( 'demo@devias.io', 'Password123!', values.email, values.password );
          
          if (result && result.error) {
            enqueueSnackbar(result.error, { variant: 'error' })
          }

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          enqueueSnackbar('Authorization error', { variant: 'error' })
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            // value={values.password || localStorage.getItem('passtologin')}
            defaultValue={localStorage.getItem('passtologin') ? localStorage.passtologin : null}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
          {/* <Box sx={{ mt: 2 }}>
            <Alert severity="info">
              <div>
                Use
                {' '}
                <b>demo@devias.io</b>
                {' '}
                and password
                {' '}
                <b>Password123!</b>
              </div>
            </Alert>
          </Box> */}
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
