import { ApiResponse } from 'src/types/generic';
import { GENERIC_ENDPOINT } from '../constants/server';
import {
  NewBathroomData,
  Order,
  OrderDate,
  OrderDBEntity,
  OrderShowroomAvailability,
  OrderWorkRequestItem,
  ORDER_STATUS,
  PRODUCT_TYPE,
} from '../types/order';
import { Invoice } from '../types/invoice';
import { createGenericApiCall, request } from './request';

// TODO: create generic function for creating api methods

export const fetchOrders = async ({
  userId,
}: { userId?: string } = {}): Promise<Order[]> => {
  const data = {
    mode: 'order',
    action: 'get',
    data: {
      user_id: userId,
    },
  };

  const response = await request.post<ApiResponse<Order[]>>(
    GENERIC_ENDPOINT,
    data
  );

  if (response.data.state === 'error') {
    throw new Error('Unable to fetch orders');
  }

  const orders = response.data.data.map(parseOrder);

  return orders;
};

export const fetchSingleOrderByUserId = createGenericApiCall<
  Order,
  { user_id: string }
>({
  errorText: 'Unable to fetch order',
  userQuery: {
    mode: 'order',
    action: 'get',
  },
  responseDataMapper: (orders) => parseOrder(orders[orders.length - 1]),
});

// TODO: check if this api is used
// export const fetchOrder = async ({ orderId }: { orderId: string }): Promise<Order[]> => {
//   const data = {
//     mode: "order",
//     action: "get",
//     data: {
//       orderId,
//     },
//   }

//   const response = await request.post<ApiResponse<Order[]>>(GENERIC_ENDPOINT, data);

//   if (response.data.state === 'error') {
//     throw new Error(`Unable to fetch order: ${orderId}`)
//   }

//   const order = parseOrder(response.data.data);

//   return order;
// }

// export const addOrder = createGenericApiCall<Order>({
//   // errorText: 'Unable to update order',
//   userQuery: {
//     mode: 'order',
//     action: 'add',
//   },
//   responseDataMapper: (order) => parseOrder(order),
// });

export const updateOrder = createGenericApiCall<
  Order,
  Partial<OrderDBEntity> | { startDate: OrderDate; id: string }
>({
  // errorText: 'Unable to update order',
  userQuery: {
    mode: 'order',
    action: 'update',
  },
  responseDataMapper: (order) => parseOrder(order),
});

type RemoveOrderProductParams = {
  productId: string;
};

export const removeOrderProduct = async (
  params: RemoveOrderProductParams
): Promise<Order> => {
  const data = {
    mode: 'order_product',
    action: 'delete',
    data: {
      productId: params.productId,
    },
  };

  const response = await request.post<ApiResponse<Order>>(
    GENERIC_ENDPOINT,
    data
  );

  if (response.data.state === 'error') {
    throw new Error('Unable to remove order product');
  }

  const order = parseOrder(response.data.data);

  return order;
};

export type AddOrderProductParams = {
  product: {
    id: string;
    type: PRODUCT_TYPE;
    placement?: string;
  };
  bathroomId: string;
};

export const addOrderProduct = async (
  params: AddOrderProductParams
): Promise<Order> => {
  const data = {
    mode: 'order_product',
    action: 'add',
    data: params,
  };

  const response = await request.post<ApiResponse<Order>>(
    GENERIC_ENDPOINT,
    data
  );

  if (response.data.state === 'error') {
    throw new Error(response.data.error_desc);
  }

  const order = parseOrder(response.data.data);

  return order;
};

type ReplaceOrderProductParams = {
  oldProduct: {
    id: string;
    type: 'option' | 'material' | 'model';
  };
  newProduct: {
    id: string;
    type: 'option' | 'material' | 'model';
    placement?: string;
  };
  bathroomId: string;
};

export const replaceOrderProduct = async (
  params: ReplaceOrderProductParams
): Promise<Order> => {
  const data = {
    mode: 'order_product_replace',
    action: 'update',
    data: params,
  };

  const response = await request.post<ApiResponse<Order>>(
    GENERIC_ENDPOINT,
    data
  );

  if (response.data.state === 'error') {
    throw new Error(response.data.error_desc);
  }

  const order = parseOrder(response.data.data);

  return order;
};

type RemoveOrderBathroomParams = {
  bathroomId: string;
};

export const removeOrderBathroom = async (
  params: RemoveOrderBathroomParams
): Promise<Order> => {
  const data = {
    mode: 'order_remove_bathroom',
    action: 'delete',
    data: params,
  };

  const response = await request.post<ApiResponse<Order>>(
    GENERIC_ENDPOINT,
    data
  );

  if (response.data.state === 'error') {
    throw new Error(`Unable to remove order bathroom: ${params.bathroomId}`);
  }

  const order = parseOrder(response.data.data);

  return order;
};

type RemoveOrderProductsParams = {
  orderId: string;
  bathroomIndex: number;
  products: Array<{
    id?: number;
    name: string;
    section: string;
  }>;
};

export const removeOrderProducts = async (
  params: RemoveOrderProductsParams
): Promise<Order> => {
  const data = {
    mode: 'order_products',
    action: 'delete',
    data: {
      order_id: params.orderId,
      bathroom_index: params.bathroomIndex,
      products: params.products,
    },
  };

  const response = await request.post<ApiResponse<Order>>(
    GENERIC_ENDPOINT,
    data
  );

  if (response.data.state === 'error') {
    throw new Error('Unable to remove order products');
  }

  const order = parseOrder(response.data.data);

  return order;
};

export const addWorkRequestItemToOrder = createGenericApiCall<
  Order,
  { orderId: string; workRequestItemId: string }
>({
  errorText: 'Unable to add work request item to order',
  userQuery: {
    mode: 'order__work_request_item',
    action: 'add',
  },
});

export const fetchShowroomAvailability =
  createGenericApiCall<OrderShowroomAvailability>({
    errorText: 'Unable to fetch showroom availability',
    userQuery: {
      mode: 'order',
      action: 'getAvailability',
    },
  });

export const updateOrderShowRoomDate = createGenericApiCall<
  void,
  { showRoomDate: OrderDate; orderId: string }
>({
  errorText: 'Unable to update showroom date',
  userQuery: {
    mode: 'order_show_room_date',
    action: 'update',
  },
});

type UpdateOrderPurchaseOrderData = {
  // key is bathroom id
  [key: string]: {
    products: Array<{ 
      id: string,
      poNameSuffix: string 
    }>
  };
};

export const updateOrderPurchaseOrderData = createGenericApiCall<
  Order,
  UpdateOrderPurchaseOrderData
>({
  errorText: 'Unable to save purchase order updates',
  userQuery: {
    mode: 'order_update_purchase_order_data',
    action: 'update',
  },
  responseDataMapper: (order) => parseOrder(order),
});

export const completeOrder = createGenericApiCall<Order, { id: string }>({
  errorText: 'Unable to complete order',
  userQuery: {
    mode: 'complete_order',
    action: 'update',
  },
  responseDataMapper: (order) => parseOrder(order),
});

export const deleteOrderWorkRequestItem = createGenericApiCall<
  void,
  { orderId: string; workRequestItemId: string }
>({
  errorText: 'Unable to delete work request item',
  userQuery: {
    mode: 'order__work_request_item',
    action: 'delete',
  },
});

export const fetchDefaultWorkRequestItems = createGenericApiCall<
  OrderWorkRequestItem,
  { id: string }
>({
  errorText: 'Unable to fetch default work request items',
  userQuery: {
    mode: 'order__default_work_request_item',
    action: 'get',
  },
});

// export const updateOrderContractor = createGenericApiCall<any, { contractorId: string, subcontractorId: string }>({
//   errorText: 'Unable update order contractor',
//   userQuery: {
//     mode: 'order__contractor',
//     action: 'update',
//   },
// })

export const updateInvoices = createGenericApiCall<
  Invoice[],
  { invoices: Partial<Invoice>[] }
>({
  errorText: 'Unable to update invoices',
  userQuery: {
    mode: 'bathroom__invoices',
    action: 'update',
  },
});

type FinalQuoteFiles = {
  quote: Blob,
  contract: Blob,
}

export const sendFinalQuote = createGenericApiCall<unknown, { orderId: string }, FinalQuoteFiles>({
  userQuery: {
    mode: 'final_order_quote_to_user',
    action: 'send',
  },
});

export const sendWorkRequest = createGenericApiCall<unknown, { orderId: string }, { workRequest: Blob }>({
  userQuery: {
    mode: 'order_work_request',
    action: 'send',
  },
});

export const sendPurchaseOrder = createGenericApiCall<unknown, { poId: string }, { purchaseOrder: Blob }>({
  userQuery: {
    mode: 'order_purchase_order',
    action: 'send',
  },
});

type ProjectManagerAttachments = {
  quote: Blob,
  contract: Blob,
  workRequest: Blob,
  purchaseOrders: Blob,
}

export const sendAttachmentsToProjectManager = createGenericApiCall<unknown, { orderId: string }, ProjectManagerAttachments>({
  userQuery: {
    mode: 'documents_to_project_manager',
    action: 'send',
  },
});

export const downloadContract = createGenericApiCall<unknown, { orderId: string }>({
  requestConfig: {
    responseType: 'blob',
  },
  userQuery: {
    mode: 'download_contract',
    action: 'get',
  },
});

type TContractData = {
  version: string;
  data: any;
}

export const updateContract = createGenericApiCall<Order, { orderId: string, contractData: TContractData }, { contract: Blob }>({
  userQuery: {
    mode: 'order_contract',
    action: 'update',
  },
});

export const addEstimate = createGenericApiCall<Order, { orderId: string }>({
  userQuery: {
    mode: 'order_estimate',
    action: 'add',
  },
});

export const addGrout = createGenericApiCall<Order, { bathroomProductId: string, groutId: string, quantity: number }>({
  userQuery: {
    mode: 'bathroom_tile_grout',
    action: 'add',
  },
});

export const updateGrout = createGenericApiCall<Order, { id: string, groutId: string, quantity: number }>({
  userQuery: {
    mode: 'bathroom_tile_grout',
    action: 'update',
  },
});

export const deleteGrout = createGenericApiCall<Order, { id: string }>({
  userQuery: {
    mode: 'bathroom_tile_grout',
    action: 'delete',
  },
});

type AddOrderBathroomArgs = {
  orderId: string,
  bathroom: NewBathroomData,
}
export const addBathroom = createGenericApiCall<Order, AddOrderBathroomArgs>({
  userQuery: {
    mode: 'order__bathroom',
    action: 'add',
  },
});


export const setBathroomRequiedOptionAnswered = createGenericApiCall<
  Order,
  { optionId: string, bathroomId: string }
>({
  userQuery: {
    mode: 'set_bathroom_required_option_answered',
    action: 'update',
  },
  responseDataMapper: (data) => parseOrder(data),
});

const parseOrder = (order: any): Order => {
  const readonly = order.status === ORDER_STATUS.COMPLETED;

  return {
    ...order,
    readonly,
    showRoomDateMs: new Date(order.showRoomDate).getTime(),
  };
};
