import { Component} from 'react'
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error})
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return <h1 style={{padding: 16}} >Something went wrong.</h1>;
    }

    return this.props.children;
  }
}