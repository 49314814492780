import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as orderApi from '../api/orderApi';

import { OrderListState } from '../types/order';

const initialState: OrderListState = {
  list: null,
  loading: false,
  error: null,
};

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async () => {
    const orders = await orderApi.fetchOrders();
    return orders;
  }
);

const orderListSlice = createSlice<OrderListState, any>({
  initialState,
  name: 'orders',
  reducers: {},
  extraReducers: {
    [fetchOrders.pending.toString()]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [fetchOrders.fulfilled.toString()]: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    },
    [fetchOrders.rejected.toString()]: (state, action) => {
      state.error = action.error; // TODO: check error and show in the UI
      state.loading = false;
    },
  },
});


export const orderListReducer = orderListSlice.reducer;
