import { useState, useEffect, useRef } from 'react';


const DocsLayout = () => {
  // const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const refCont = useRef(null);

  useEffect(()=>{
    if (refCont.current && refCont.current.style.visibility === 'hidden') {
      loadHome(refCont);
    }
  }, [refCont])

  return (
    <div style={{overflow: 'hidden', height: '100%', visibility: 'hidden'}} ref={refCont}>
    </div>
  );
};
export default DocsLayout;



async function fetchHtmlAsText(url) {
  return await (await fetch(url)).text();
}
async function loadHome(refCont) {
    const contentDiv = refCont.current;
    contentDiv.innerHTML = await fetchHtmlAsText("/docs/index.html");
    
    setTimeout(()=>{
      contentDiv.style.visibility = 'visible';
    }, 600);
}
