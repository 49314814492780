import { Box, Container, Grid, Typography } from '@material-ui/core';

export const PermissionAccessError = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                You don't have permissions to view this page
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}