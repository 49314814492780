import { createGenericApiCall } from './request';

import { NewUser, User, UserEdit, UsersStats, USER_ROLE } from '../types/user';

type FetchUsersArgs = Partial<User> & {
  roles?: USER_ROLE[],
  query?: string,
}

export const fetchUsers = createGenericApiCall<User[], FetchUsersArgs | void>({
  userQuery: {
    mode: 'userV2',
    action: 'get',
  },
  responseDataMapper: (users) => users.map(parseUser)
});

export const fetchUser = createGenericApiCall<User, Partial<User>>({
  errorText: 'Unable to fetch user',
  userQuery: {
    mode: 'userV2',
    action: 'get',
  },
});

export const fetchCurrentUser = createGenericApiCall<User, void>({
  errorText: 'Unable to fetch current user',
  userQuery: {
    mode: 'current_user',
    action: 'get',
  },
});

export const fetchUsersStats = createGenericApiCall<UsersStats, { startDate: number, endDate: number }>({
  userQuery: {
    mode: 'user_stats',
    action: 'get',
  },
});

export const createUser = createGenericApiCall<User[], NewUser>({
  userQuery: {
    mode: 'userV2',
    action: 'add',
  },
});

export const updateUser = createGenericApiCall<User[], UserEdit>({
  userQuery: {
    mode: 'userV2',
    action: 'update',
  },
});

const parseUser = (user: any): User => {
  return {
    ...user,
    fullName: user.name ? `${user.name} ${user.lastName}` : undefined,
    createdDate: user.createdDate * 1000,
  }
}