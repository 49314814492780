import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import PermissionsGuard from './components/PermissionsGuard';
// import BlogLayout from './components/blog/BlogLayout';
// import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import { PERMISSION_ENTITY } from './hooks/usePermissions';
import ErrorBoundary from './ErrorBoundary';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <ErrorBoundary><Component {...props} /></ErrorBoundary>
  </Suspense>
)

const pm = (Component, permissions) => {
  return (
    <PermissionsGuard permissions={permissions}>
      <Component />
    </PermissionsGuard>
  )
}

// Browse pages

// const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
// const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
// const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
// const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
// const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
// const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
// const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
// const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
// const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
// const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
// const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
// const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
// const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

// const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
// const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
// const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

// const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
// const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
// const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));




// const AdminsList = Loadable(lazy(() => import('./pages/dashboard/AdminsList')));
// const AdminsDetails = Loadable(lazy(() => import('./pages/dashboard/AdminsDetails')));

const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const CustomerStats = Loadable(lazy(() => import('./pages/dashboard/CustomerStats')));

// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

const ProductPaintColorList = Loadable(lazy(() => import('./pages/dashboard/ProductPaintColorList')));
const ProductPaintColorDetails = Loadable(lazy(() => import('./pages/dashboard/ProductPaintColorDetails')));

const OptionDetails = Loadable(lazy(() => import('./pages/dashboard/OptionDetails')));
const OptionList = Loadable(lazy(() => import('./pages/dashboard/OptionList')));

const WorkRequestItemList = Loadable(lazy(() => import('./pages/dashboard/WorkRequestItemList')));
const WorkRequestItemDetails = Loadable(lazy(() => import('./pages/dashboard/WorkRequestItemDetails')));

const SupplierList = Loadable(lazy(() => import('./pages/dashboard/SupplierList')));
const SupplierDetails = Loadable(lazy(() => import('./pages/dashboard/SupplierDetails')));

const AssetsList = Loadable(lazy(() => import('./pages/dashboard/AssetsList')));
const AssetsCreate = Loadable(lazy(() => import('./pages/dashboard/AssetsCreate')));

const UserList = Loadable(lazy(() => import('./pages/dashboard/UserList')));
const UserDetails = Loadable(lazy(() => import('./pages/dashboard/UserDetails')));

const ContractorList = Loadable(lazy(() => import('./pages/dashboard/ContractorList')));
const ContractorDetails = Loadable(lazy(() => import('./pages/dashboard/ContractorDetails')));

const ContractEdit = Loadable(lazy(() => import('./pages/dashboard/ContractEdit.js')));
const ContractCreate = Loadable(lazy(() => import('./pages/dashboard/ContractCreate')));

const GroutList = Loadable(lazy(() => import('./pages/dashboard/GroutList')));
const GroutDetails = Loadable(lazy(() => import('./pages/dashboard/GroutDetails')));

const Settings = Loadable(lazy(() => import('./pages/dashboard/Settings')));

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

// const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
// const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
// const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

// const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
// const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Social pages

// const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
// const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

// const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
// const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Home = Loadable(lazy(() => import('./pages/Home')));
// const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  // {
  //   path: 'blog',
  //   element: <BlogLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <BlogPostList />
  //     },
  //     {
  //       path: 'new',
  //       element: <BlogPostCreate />
  //     },
  //     {
  //       path: ':postId',
  //       element: <BlogPostDetails />
  //     }
  //   ]
  // },
  // {
  //   path: 'contact',
  //   element: <Contact />
  // },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />,
      },
      // {
      //   path: 'account',
      //   element: <Account />
      // },
      // {
      //   path: 'analytics',
      //   element: <Analytics />
      // },
      // {
      //   path: 'calendar',
      //   element: <Calendar />
      // },
      // {
      //   path: 'chat',
      //   children: [
      //     {
      //       path: '/',
      //       element: <Chat />
      //     },
      //     {
      //       path: 'new',
      //       element: <Chat />
      //     },
      //     {
      //       path: ':threadKey',
      //       element: <Chat />
      //     }
      //   ]
      // },
      {
        path: 'assets',
        children: [
          {
            path: '/',
            element: pm(AssetsList, PERMISSION_ENTITY.ASSET),
          },
          {
            path: 'new',
            element: pm(AssetsCreate, PERMISSION_ENTITY.ASSET),
          },
          {
            path: '3d/:id',
            element: pm(AssetsCreate, PERMISSION_ENTITY.ASSET),
          },
          {
            path: '2d/:id',
            element: pm(AssetsCreate, PERMISSION_ENTITY.ASSET),
          },
          {
            path: 'type/:id',
            element: pm(AssetsCreate, PERMISSION_ENTITY.ASSET),
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: pm(UserList, PERMISSION_ENTITY.USER),
          },
          {
            path: 'new',
            element: pm(UserDetails, PERMISSION_ENTITY.USER),
          },
          {
            path: ':id',
            element: pm(UserDetails, PERMISSION_ENTITY.USER),
          }
        ]
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: pm(CustomerList, PERMISSION_ENTITY.CUSTOMER),
          },
          {
            path: 'statistics',
            element: pm(CustomerStats, PERMISSION_ENTITY.STATISTICS),
          },
          {
            path: ':customerId',
            element: pm(CustomerDetails, PERMISSION_ENTITY.CUSTOMER),
          },
          {
            path: ':customerId/edit',
            element: pm(CustomerEdit, PERMISSION_ENTITY.CUSTOMER),
          }
        ]
      },
      // {
      //   path: 'invoices',
      //   children: [
      //     {
      //       path: '/',
      //       element: <InvoiceList />
      //     },
      //     {
      //       path: ':invoiceId',
      //       element: <InvoiceDetails />
      //     }
      //   ]
      // },
      // {
      //   path: 'kanban',
      //   element: <Kanban />
      // },
      // {
      //   path: 'mail',
      //   children: [
      //     {
      //       path: '/',
      //       element: (
      //         <Navigate
      //           to="/dashboard/mail/all"
      //           replace
      //         />
      //       )
      //     },
      //     {
      //       path: 'label/:customLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: 'label/:customLabel/:emailId',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel/:emailId',
      //       element: <Mail />
      //     }
      //   ]
      // },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: pm(OrderList, PERMISSION_ENTITY.ORDER),
          },
          {
            path: 'work-request-items',
            children: [
              {
                path: '/',
                element: pm(WorkRequestItemList, PERMISSION_ENTITY.ORDER),
              },
              {
                path: 'new',
                element: pm(WorkRequestItemDetails, PERMISSION_ENTITY.ORDER),
              },
              {
                path: ':id',
                element: pm(WorkRequestItemDetails, PERMISSION_ENTITY.ORDER),
              }
            ]
          },
          // {
          //   path: ':orderId',
          //   element: < />
          // },
        ]
      },
      {
        path: 'contracts',
        children: [
          {
            path: '/',
            element: pm(ContractEdit, PERMISSION_ENTITY.SALER),
          },
          {
            path: 'new',
            element: pm(ContractCreate, PERMISSION_ENTITY.SALER),
          }
        ]
      },
      // {
      //   path: 'finance',
      //   element: <Finance />
      // },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: pm(ProductList, PERMISSION_ENTITY.PRODUCT),
          },
          {
            path: 'new',
            element: pm(ProductCreate, PERMISSION_ENTITY.PRODUCT),
          },
          {
            path: 'paint-colors',
            children: [
              {
                path: '/',
                element: pm(ProductPaintColorList, PERMISSION_ENTITY.PRODUCT),
              },
              {
                path: '/new',
                element: pm(ProductPaintColorDetails, PERMISSION_ENTITY.PRODUCT),
              },
              {
                path: '/:id',
                element: pm(ProductPaintColorDetails, PERMISSION_ENTITY.PRODUCT),
              }
            ]
          },
          {
            path: 'grouts',
            children: [
              {
                path: '/',
                element: pm(GroutList, PERMISSION_ENTITY.PRODUCT),
              },
              {
                path: '/new',
                element: pm(GroutDetails, PERMISSION_ENTITY.PRODUCT),
              },
              {
                path: '/:id',
                element: pm(GroutDetails, PERMISSION_ENTITY.PRODUCT),
              }
            ]
          }
        ]
      },
      {
        path: 'product-upgrades',
        children: [
          {
            path: '/',
            element: pm(OptionList, PERMISSION_ENTITY.PRODUCT_UPGRADE),
          },
          {
            path: 'new',
            element: pm(OptionDetails, PERMISSION_ENTITY.PRODUCT_UPGRADE),
          },
          {
            path: ':id',
            element: pm(OptionDetails, PERMISSION_ENTITY.PRODUCT_UPGRADE),
          }
        ]
      },
      {
        path: 'suppliers',
        children: [
          {
            path: '/',
            element: pm(SupplierList, PERMISSION_ENTITY.SUPPLIER),
          },
          {
            path: 'new',
            element: pm(SupplierDetails, PERMISSION_ENTITY.SUPPLIER),
          },
          {
            path: ':id',
            element: pm(SupplierDetails, PERMISSION_ENTITY.SUPPLIER),
          }
        ]
      },
      {
        path: 'contractors',
        children: [
          {
            path: '/',
            element: pm(ContractorList, PERMISSION_ENTITY.CONTRACTOR),
          },
          {
            path: 'new',
            element: pm(ContractorDetails, PERMISSION_ENTITY.CONTRACTOR),
          },
          {
            path: ':id',
            element: pm(ContractorDetails, PERMISSION_ENTITY.CONTRACTOR),
          }
        ]
      },
      // {
      //   path: 'projects',
      //   children: [
      //     {
      //       path: 'browse',
      //       element: <ProjectBrowse />
      //     },
      //     {
      //       path: 'new',
      //       element: <ProjectCreate />
      //     },
      //     {
      //       path: ':projectId',
      //       element: <ProjectDetails />
      //     }
      //   ]
      // },
      {
        path: 'settings',
        children: [
          {
            path: '/',
            element: pm(Settings, PERMISSION_ENTITY.SETTINGS),
          },
        ]
      }
      // {
      //   path: 'social',
      //   children: [
      //     {
      //       path: 'feed',
      //       element: <SocialFeed />
      //     },
      //     {
      //       path: 'profile',
      //       element: <SocialProfile />
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      }
      // {
      //   path: 'customers',
      //   children: [
      //     {
      //       path: '/',
      //       element: <CustomerList />
      //     },
      //     {
      //       path: ':customerId',
      //       element: <CustomerDetails />
      //     },
      //     {
      //       path: ':customerId/edit',
      //       element: <CustomerEdit />
      //     }
      //   ]
      // }
    ]
  },
  {
    path: 'documents',
    element: <DocsLayout />,
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/documents/overview/welcome"
            replace
          />
        )
      },
      {
        path: '*',
        element: <Docs />
      }
    ]
  },
  // {
  //   path: '*',
  //   element: <MainLayout />,
  //   children: [
  //     {
  //       path: '/',
  //       element: <Home />
  //     },
  //     {
  //       path: 'browse',
  //       element: <BrowseLayout />,
  //       children: [
  //         {
  //           path: '/',
  //           element: <Browse />
  //         },
  //         {
  //           path: '/buttons',
  //           element: <BrowseButtons />
  //         },
  //         {
  //           path: '/inputs',
  //           element: <BrowseInputs />
  //         },
  //         {
  //           path: '/charts',
  //           element: <BrowseCharts />
  //         },
  //         {
  //           path: '/colors',
  //           element: <BrowseColors />
  //         },
  //         {
  //           path: '/data-display/detail-lists',
  //           element: <BrowseDetailLists />
  //         },
  //         {
  //           path: '/data-display/quick-stats',
  //           element: <BrowseQuickStats />
  //         },
  //         {
  //           path: '/data-display/tables',
  //           element: <BrowseTables />
  //         },
  //         {
  //           path: '/forms',
  //           element: <BrowseForms />
  //         },
  //         {
  //           path: '/modals',
  //           element: <BrowseModals />
  //         },
  //         {
  //           path: '/lists/grouped-lists',
  //           element: <BrowseGroupedLists />
  //         },
  //         {
  //           path: '/lists/grid-lists',
  //           element: <BrowseGridLists />
  //         },
  //         {
  //           path: '/typography',
  //           element: <BrowseTypography />
  //         }
  //       ]
  //     },
  //     {
  //       path: 'checkout',
  //       element: <Checkout />
  //     },
  //     {
  //       path: 'pricing',
  //       element: <Pricing />
  //     },
  //     {
  //       path: '401',
  //       element: <AuthorizationRequired />
  //     },
  //     {
  //       path: '404',
  //       element: <NotFound />
  //     },
  //     {
  //       path: '500',
  //       element: <ServerError />
  //     },
  //     {
  //       path: '*',
  //       element: <NotFound />
  //     }
  //   ]
  // }
];

export default routes;
