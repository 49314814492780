import axios from 'axios';
import { getStore } from 'src/store/getStore';
import { ApiResponse } from 'src/types/generic';

import { ADMIN_BASE_URL, GENERIC_ENDPOINT, APP_SERVER_NAME, APP_SERVER_NAME_HEADER } from '../constants/server';

export const request = axios.create({
  baseURL: ADMIN_BASE_URL,
  headers: {
    Accept: '*/*',
    [APP_SERVER_NAME_HEADER]: APP_SERVER_NAME,
  }
});

request.interceptors.request.use(
  (config) => {
    if (config.url === GENERIC_ENDPOINT) {
      const accessTokenAdm = window.localStorage.getItem('accessTokenAdm');

      const dataClone = {...config.data};
      const files: Record<string, Blob> = dataClone.files || [];

      if (files) {
        delete dataClone.files;
      }

      const data = {
        ...dataClone,
        token: accessTokenAdm,
      }
      const formData = new FormData();
      formData.append('user_query', JSON.stringify(data));

      Object.entries(files).forEach(([key, file]) => {
        formData.append(key, file);
      })

      config.data = formData
    }

    return config;
  },
  (error) => {
    // reload the page when token is invalid
    return Promise.reject(error);
  }
);


request.interceptors.response.use(
  (response) => {
    const store = getStore();

    if (response.data.state === 'error' && response.data.data.errorCode === 401) {
      store.dispatch({ type: 'auth/logout' });
    }

    return response;
  }, 
  (error) => {
    console.log('error', error, getStore());
    return Promise.reject(error)
  },
);


// TODO: check AxiosMockAdapter
// export const mock = new AxiosMockAdapter(request, { delayResponse: 0 });

type CreateGenericApiHandlerArgs<Params, Response> = {
  errorText?: string,
  requestConfig?: any, // axios request data
  responseDataMapper?: (responseData: any) => Response,
  userQuery: {
    mode: string,
    action: string,
    data?: any,
    paramsToDataMapper?: (params: Params) => any,
  }
}

export const createGenericApiCall = <Response, Params = void, Files = void>(args: CreateGenericApiHandlerArgs<Params, Response>) => {
  const {
    errorText,
    requestConfig,
    responseDataMapper = (data) => data,
    userQuery: {
      mode,
      action,
      // data = {},
      paramsToDataMapper,
    },
  } = args;

  async function callApi(params: Params, files: Files): Promise<Response> {
    const axiosRequestData = { 
      mode, 
      action,
      data: paramsToDataMapper ? paramsToDataMapper(params) : (params || {}),
      files,
    }
    // console.log('axiosRequestData, requestConfig', axiosRequestData, requestConfig );
    const response = await request.post<ApiResponse<Response>>(GENERIC_ENDPOINT, axiosRequestData, requestConfig);
    const total = parseInt(response.headers['pagination-total']);

    if (response.data.state === 'error') {
      throw new Error(errorText || response.data.error_desc);
    }
  
    try {
      if (requestConfig && requestConfig.responseType === 'blob') {
        return (response.data as any);
      }

      if (total) {
        // TODO: create generic type for it
        return {
          data: responseDataMapper(response.data.data),
          total,
          paginationTotalEnabled: true,
        } as any
      }

      return responseDataMapper(response.data.data);
    } catch (e) {
      throw new Error(`Unable to parse response for mode: "${mode}", action: "${action}"`);
    }
  }

  return callApi;
}